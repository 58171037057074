<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <div class="card_out" v-if="data">
        <div class="doctor_card">
          <div class="doctor_name">{{ data.doctor_name }}</div>
          <div class="doctor_depart">
            <span>{{ data.doctor_title_label }}</span>
            <div class="split_line"></div>
            <span>{{ hospital_info.department }}</span>
          </div>
          <div class="hospital_box">
            <div class="hospital_level">{{ hospital_info.level }}</div>
            <div class="hospital_name">
              <span>{{ hospital_info.hospital_name }}</span>
              <img src="../../assets/img/report-arrow.png" alt="" width="12" />
            </div>
          </div>
          <div class="long_box">擅长: {{ data.desc || "暂无信息" }}</div>
          <div class="short_box">简介: {{ data.short || "暂无信息" }}</div>
          <img
            v-if="data.avatar_url"
            :src="data.avatar_url"
            alt=""
            class="doctor_img"
            width="60"
          />
          <img
            v-else
            src="../../assets/img/online/doctor.png"
            alt=""
            class="doctor_img"
            width="60"
          />
        </div>
        <div class="round_1"></div>
        <div class="round_2"></div>
      </div>
      <div class="schedual_card" v-if="data">
        <div class="schedual_top">
          <div class="schedual_title">排班信息</div>
          <div class="schedual_fresh" @click="refresh">
            <img src="../../assets/img/refresh.png" alt="" width="16" />
            <span>刷新排班</span>
          </div>
        </div>
        <div class="schedual_name">
          <div class="schedual_line"></div>
          <span
            >{{ hospital_name }}
            <template v-if="$route.query.department_name"
              >({{ $route.query.department_name }})</template
            ></span
          >
        </div>
        <div class="schedual_weeks">
          <div class="schedual_week" v-for="(i, idx) in week_list" :key="idx">
            {{ week_arr[i] }}
          </div>
        </div>
        <div class="schedual_day_box">
          <div
            class="item_out"
            v-for="(item, index) in list"
            :key="index"
            @click="choose_time(item)"
          >
            <div :class="['schedual_day_item', { active: item.status === 1 }]">
              <div class="schedual_month">{{ month_arr[item.month] }}</div>
              <div class="schedual_date">{{ item.date }}</div>
              <div class="schedual_order">预约</div>
            </div>
          </div>
        </div>
      </div>

      <div class="schedual_rule_out">
        <div class="schedual_rule">
          <div class="schedual_line s2"></div>
          <span>预约规则</span>
        </div>
        <div class="schedual_text">
          一人一天可约2次，同科室一天可约1次；<br />
          诊查费由医院自行设定，平台未收取任何额外费用；<br />
          如遇专家门诊临时停诊，神农健康客服会在第一时间通知您，但不排除停诊信息获取失败或通知不到的情况出现如有任何问题可致电神农健康客服8888888寻求帮助。因停诊带来的损失，神农健康和个人医生不承担任何赔偿责任。
        </div>
      </div>
    </div>

    <van-popup
      v-model="show_today"
      round
      closeable
      position="bottom"
      :style="{ minHeight: '60%' }"
    >
      <div class="pop_out" v-if="recent_date">
        <div class="pop_title">选择预约时段</div>
        <div class="pop_date">
          {{ format_date(recent_date.dt, "yyyy年MM月dd日") }}
        </div>
        <div
          class="am_box"
          v-if="recent_date.time_am && recent_date.time_am.length"
        >
          <div class="am_title">上午</div>
          <div class="am_list">
            <div
              class="am_item_out"
              v-for="(item, index) in recent_date.time_am"
              :key="index"
            >
              <div
                :class="['am_item', { disabled: item.count === 0 }]"
                @click="jump_to_next(item, 0, recent_date)"
              >
                {{ item.time }} ({{ item.count }}个空号)
              </div>
            </div>
          </div>
        </div>
        <div
          class="am_box"
          v-if="recent_date.time_pm && recent_date.time_pm.length"
        >
          <div class="am_title">下午</div>
          <div class="am_list">
            <div
              class="am_item_out"
              v-for="(item, index) in recent_date.time_pm"
              :key="index"
            >
              <div
                :class="['am_item', { disabled: item.count === 0 }]"
                @click="jump_to_next(item, 1, recent_date)"
              >
                {{ item.time }} ({{ item.count }}个空号)
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { get_doctor_info } from "./service"
import { format_date } from "../../utils/format"
import { mapMutations, mapState } from "vuex"

export default {
  data() {
    return {
      hospital_name: GLOBAL_CONFIG.hospital_name,
      loading: false,
      week_arr: ["日", "一", "二", "三", "四", "五", "六"],
      week_list: [],
      month_arr: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      data: null,
      list: [],
      prama: {},
      price: null,
      id: null,
      today: new Date(),
      show_today: false,
      recent_date: null,
    }
  },
  computed: {
    ...mapState("hospital", {
      hospital_info: (state) => state.hospital_info,
    }),
  },
  components: {},
  watch: {},
  created() {
    this.id = this.$route.query.doctor_id
    // this.price = this.$route.query.price;
    this.prama.hospital_id = this.$route.query.hospital_id
    this.prama.department_code = this.$route.query.department_code
    this.get_list()
  },
  destroyed() {},
  methods: {
    ...mapMutations("appointment", ["clearState"]),
    ...mapMutations("appointment", ["updateAppointment"]),
    format_date,
    refresh() {
      this.get_list()
    },
    set_defalt(scheduals) {
      this.list = []
      for (let i = 0; i < 20; i++) {
        let day = new Date().clone()
        let date = day.setDate(day.getDate() + i)
        this.list.push({
          dt: date,
          days: new Date(date).getDay(),
          month: new Date(date).getMonth(),
          date: new Date(date).getDate(),
          status: 0,
          time_am: [],
          time_pm: [],
        })
      }
      scheduals?.forEach((item) => {
        this.list?.forEach((el) => {
          if (
            new Date(item.date).toDateString() ===
            new Date(el.dt).toDateString()
          ) {
            el.price = item.price
            el.status = item.status
            el.time_am = item.time_am || []
            el.time_pm = item.time_pm || []
          }
        })
      })

      this.week_list = []
      let num = this.list.length > 7 ? 7 : this.list.length
      for (let s = 0; s < num; s++) {
        this.week_list.push(this.list[s].days)
      }
    },
    async get_list() {
      this.loading = true
      try {
        const { data } = await get_doctor_info(this.id, this.prama)
        this.data = data
        this.set_defalt(this.data.schedules)
      } finally {
        this.loading = false
      }
    },
    choose_time(item) {
      if (item.status === 1) {
        this.show_today = true
        this.recent_date = item
      }
    },
    jump_to_next(item, noon, date_item) {
      let time_list = []
      let arr_am = []
      if (this.recent_date.time_am && this.recent_date.time_am.length) {
        arr_am = this.recent_date.time_am.filter((item) => {
          return item.count > 0
        })
      }

      let arr_pm = []
      if (this.recent_date.time_pm && this.recent_date.time_pm.length) {
        arr_pm = this.recent_date.time_pm.filter((item) => {
          return item.count > 0
        })
      }
      time_list = [...arr_am, ...arr_pm]

      const appointment_date = {
        hospital_name: this.hospital_info.hospital_name,
        hospital_id: this.hospital_info.hospital_id || this.prama.hospital_id,
        department:
          this.$route.query.department_name ||
          this.hospital_info.department ||
          this.prama.department_code,
        doctor_name: this.data.doctor_name,
        doctor_id: this.data.doctor_id,
        doctor_title_label: this.data.doctor_title_label,
        date: this.recent_date.dt,
        am_pm: noon ? "下午" : "上午",
        time: item.time,
        row_id: item.row_id,
        price: date_item.price,
        time_list: time_list,
      }
      this.updateAppointment(appointment_date)
      this.$router.push({
        path: "/appointment-information",
      })
    },
  },
}
</script>
<style scoped>
.g_main_content {
  padding: 47px 12px 0;
  background: #f2f7f8;
}

.card_out {
  position: relative;
}

.doctor_card {
  min-height: 177px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 12px;
  position: relative;
  z-index: 2;
}

.doctor_name {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: #242938;
  padding-bottom: 8px;
}

.doctor_depart {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #565656;
  display: flex;
  align-items: center;
}

.split_line {
  width: 1px;
  height: 13px;
  background: #565656;
  margin: 0 8px;
}

.hospital_box {
  display: flex;
  align-items: center;
  padding: 4px 0 12px;

  & img {
    margin-left: 4px;
  }
}

.hospital_level {
  padding: 0 4px;
  height: 20px;
  line-height: 20px;
  background: rgba(24, 144, 255, 0.1);
  border-radius: 2px;
  margin-right: 8px;
  font-size: 12px;
  color: #1890ff;
}

.hospital_name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #565656;
  display: flex;
  align-items: center;
}

.long_box,
.short_box {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  max-height: 40px;
  color: #898995;
  padding-bottom: 4px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.doctor_img {
  position: absolute;
  right: 16px;
  top: -23px;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.round_1 {
  width: 92px;
  height: 92px;
  position: absolute;
  right: 0;
  top: -37px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.015);
  box-shadow: 0px -30px 60px rgba(138, 149, 158, 0.2);
  z-index: 1;
}

.round_2 {
  width: 118px;
  height: 118px;
  position: absolute;
  right: -12px;
  top: -47px;
  background: #ffffff;
  box-shadow: 0px -30px 60px rgba(138, 149, 158, 0.2);
  border-radius: 50%;
}

.schedual_card {
  min-height: 354px;
  background: #ffffff;
  opacity: 1;
  border-radius: 8px;
  padding: 16px 12px;
  margin-top: 12px;
}

.schedual_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.schedual_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #242938;
}

.schedual_fresh {
  & span {
    font-size: 12px;
    line-height: 17px;
    color: #1890ff;
    margin-left: 4px;
  }
}

.schedual_name {
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  display: flex;
  align-items: center;
}

.schedual_rule {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #242938;
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.schedual_line {
  width: 4px;
  height: 13px;
  background: linear-gradient(180deg, #a5d5ff 0%, #0088ff 100%);
  opacity: 1;
  border-radius: 5px;
  margin-right: 8px;

  &.s2 {
    width: 6px;
    height: 15px;
    background: linear-gradient(180deg, #a5d5ff 0%, #0088ff 100%);
  }
}

.schedual_weeks {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.schedual_week {
  width: calc(100% / 7);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #c8c9cc;
}

.schedual_day_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item_out {
  width: calc(100% / 7);
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
}

.schedual_day_item {
  width: 40px;
  text-align: center;
  height: 68px;
  padding: 12px 0 8px;

  border-radius: 4px;

  &.active {
    background: #1890ff;

    & .schedual_month,
    & .schedual_date,
    & .schedual_order {
      color: #fff;
    }
  }
}

.schedual_month {
  font-size: 10px;
  line-height: 14px;
  color: #565656;
  opacity: 1;
}

.schedual_date {
  font-size: 14px;
  line-height: 20px;
  color: #565656;
}

.schedual_order {
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}

.schedual_rule_out {
  padding: 0 12px 60px 12px;
}

.schedual_text {
  padding-top: 12px;
  font-size: 12px;
  line-height: 24px;
  color: #565656;
}

.pop_out {
  padding: 0 12px;
}

.pop_title {
  padding: 17px 0 10px 0;
  text-align: center;
  font-size: 16px;
  color: #323233;
  font-weight: 500;
}

.pop_date {
  padding: 16px 0;
  font-size: 16px;
  color: #565656;
}

.am_title {
  font-size: 14px;
  line-height: 20px;
  color: #898995;
}
.am_list {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.am_item_out {
  width: 50%;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
}

.am_item {
  width: 168px;
  height: 40px;
  background: #f7f8fa;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #1890ff;

  &.disabled {
    color: #ccc;
  }
}
</style>
